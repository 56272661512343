.machineHeader{
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
    padding-left: 10px;
    /* margin-top: -0.8rem; */
}
.machineimagelogo{
    height: 2rem;
    width: 5rem;
}
.roomName{
    font-size: 1rem;
}

.machineheading{
    font-size: 0.8rem;
    color: gray;
    width: 65%;
}
@media screen and (max-width:750px) {
    .machineheading{
        width: 100%;
    }
    .machineHeader{
        flex-wrap: wrap;
    }
    .roomName{
        padding-right: 2rem;
    }
}