/* .machineMainContainer{
    padding: 4rem 3rem;
} */
.machineMainContainer{
    height: 100%;
    position: relative;

}

.machineMainContainer .machineCard{
    display: grid;
    grid-template-columns: repeat(5,2fr);
    /* flex-wrap: wrap; */
    width: 70%;
    margin: auto;
    padding-top: 5rem;
}
.machineMainContainer .machinemain{
    background-color: rgb(228, 220, 199);
    display: flex;
    flex-direction: column;
    height: 5rem;
    width: 8rem;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin:5px;
    text-align: center;
    font-size: 12px;
}

.machineMainContainer .machinemain:hover{
    background-color: #3e4492;
    color: white;
    /* background-color: rgb(233, 205, 151); */
}

@media screen and (max-width: 900px) and (min-width:750px) {
    .machineMainContainer .machineCard{
        grid-template-columns: repeat(5,2fr);
        width: 90%;
    }
}
@media screen and (max-width: 750px) and (min-width:600px){
    .machineMainContainer .machineCard{
        grid-template-columns: repeat(4,2fr);
        width: 90%;
    }
}
@media screen and (max-width: 600px) and (min-width:450px){
    .machineMainContainer .machineCard{
        grid-template-columns: repeat(3,2fr);
        width: 90%;
    }
}
@media screen and (max-width: 450px){
    .machineMainContainer .machineCard{
        grid-template-columns: repeat(2,2fr);
        width: 90%;
    }
}